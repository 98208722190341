html body {
  margin: 0;
  padding: 0;
  color: #495057;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fb !important;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

html {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav a {
  color: inherit;
}
.nav a:hover {
  color: inherit;
}

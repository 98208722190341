.Assignment {
  &__arrow--left {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 45%;
    z-index: 1;
  }

  &__arrow--right {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 45%;
    z-index: 1;
  }
}
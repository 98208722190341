.Question__options {
    display: flex;
}

.Question__option {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: .25rem;
    padding: .25rem;
    border-radius: 3px;
    flex: 2;
    text-align: center;
}

.Question__option:not(:first-child) {
    margin-left: .5rem;
}

.Question__option:hover {
    color: #6e7687;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.024);
    cursor: pointer;
}

.Question__option--selected {
    color: #467fcf;
    border-color: #467fcf;
}

.Question__footer {
    display: flex;
    margin-top: 1rem;
}

.Question__next {
    margin-left: auto;
}

.Question__next:hover {
    color: #467fcf;
    cursor: pointer;
}

.Question__previous:hover {
    color: #467fcf;
    cursor: pointer;
}

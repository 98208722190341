.TopNav {
    background-color: #FFF;
}

.TopNav__header {
    border-bottom: 1px solid rgba(0,40,100,.12);
}

.TopNav__logo {
    margin-right: 8px;
}
.Register {
  text-align: center;
  padding: 32px;

  &__button {
    margin-top: 16px !important;
  }

  &__buttons {
    margin: 16px;
  }

  &__description {
    margin-top: 16px;
  }
}

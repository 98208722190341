.card {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.05);
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
}

.nav {
    color: #9aa0ac;
}
.nav-link {
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
}

.nav-link svg {
    margin-right: 0.25rem;
}

.nav-item:hover {
    color: #6e7687;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.024);
}

.nav-link.active {
    border-color: #467fcf;
    color: #467fcf;
    /*background: transparent;*/
}

.card-header {
    background: none;
}

.card-title {
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 400;
}

.card-title:only-child {
    margin-bottom: 0;
}

.table {
    margin-bottom: 0;
}

.table thead th {
    border-top: 0;
    border-bottom-width: 1px;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.table th {
    color: #9aa0ac;
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 400;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: inherit;
}

.table-hover tbody tr:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05) !important;
}

.table-striped tbody tr:nth-of-type(2n) {
    background-color: rgba(0,0,0,.02);
}

 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
     margin-bottom: 0.66em;
     font-family: inherit;
     font-weight: 600;
     line-height: 1.1;
     color: inherit;
 }

h1,
.h1 {
    font-size: 2rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.125rem;
}

h5,
.h5 {
    font-size: 1rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.App__content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    flex: 1;
}